import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-m-card-question-phone',
    templateUrl: './m-card-question-phone.component.html',
    styleUrls: ['./m-card-question-phone.component.scss'],
})
export class MCardQuestionPhoneComponent implements OnInit {

  @Input()
  public establishmentName = '';
  @Input()
  public phoneNumber = '';

  constructor() { }

  ngOnInit() {}

}
