import { Component, OnDestroy, OnInit } from '@angular/core';
import { GuestsService } from '../../../services/guests.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { RegistrationService } from '../../../services/registration.service';
import { Table } from '../../../generated/graphql';

@Component({
    selector: 'app-c-guests-current',
    templateUrl: './c-guests-current.component.html',
    styleUrls: ['./c-guests-current.component.scss'],
})
export class CGuestsCurrentComponent implements OnInit, OnDestroy {

    public subscriptions$ = new Subscription();
    public currentTables$: BehaviorSubject<Table[]> = new BehaviorSubject([]);

    constructor(
        private guestsService: GuestsService,
        private registrationService: RegistrationService,
    ) {
    }

    ngOnInit() {
        this.getCurrentClaims();
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    public rejectClaim(event) {
        this.guestsService.rejectClaim(event.tableId).then(() => {
            this.getCurrentClaims();
        });
    }

    public getCurrentClaims() {
        this.subscriptions$.add(combineLatest([
            this.registrationService.state.token,
            this.registrationService.state.restaurantId
        ]).subscribe((combined) => {
            console.log('getCurrentClaims');
            if (combined[0] !== '' && combined[1] !== '') {
                this.guestsService.getCurrentRestaurantClaims().then((response) => {
                    this.currentTables$.next(response.data.restaurantClaims.data as Table[]);
                });
            }
        }));
    }

}
