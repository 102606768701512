import { AfterContentInit, Component, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RegistrationService } from '../../../services/registration.service';
import { StorageService } from '../../../services/ionic-storage.service';
import { RestaurantsService } from '../../../services/restaurants.service';
import { RestaurantImage } from '../../../interfaces/restaurant-image';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-c-restaurant-images',
    templateUrl: './c-restaurant-images.component.html',
    styleUrls: ['./c-restaurant-images.component.scss'],
})
export class CRestaurantImagesComponent implements AfterContentInit, OnDestroy {

    @Input()
    imageType = 'image';

    public filesExisting: BehaviorSubject<RestaurantImage[]> = new BehaviorSubject([]);
    public files: BehaviorSubject<File[]> = new BehaviorSubject([]);
    public convertedFiled = [];
    public subscriptions$ = new Subscription();

    constructor(
        private registrationService: RegistrationService,
        private storageService: StorageService,
        private restaurantsService: RestaurantsService,
        private messageService: MessageService,
        private translate: TranslateService,
    ) {
    }

    public async urlToObject(image) {
        const response = await fetch(image);
        // here image is url/location of image
        const blob = await response.blob();
        return new File([blob], 'image.jpg', { type: blob.type });
    }

    public setup() {
        this.files.next([]);
        this.filesExisting.next([]);
    }

    public getRestaurantPics() {
        this.storageService.get('restaurantId').then((id) => {
            if (id) {
                this.restaurantsService.getRestaurantById(id).then((res) => {
                    // console.log('getRestaurantById', res);
                    const restaurant = res.data.restaurantById;
                    const pics = this.imageType === 'image' ? restaurant.restaurantPhotos : restaurant.menus;
                    pics.forEach((photo) => {
                        // console.log('restaurantPhotos', photo);
                        const pic = this.imageType === 'image' ? photo.image : photo.attachment;
                        this.urlToObject(pic).then((file) => {
                            const existingImage: RestaurantImage = {
                                id: photo.id,
                                image: file,
                            };
                            this.filesExisting.next([...this.filesExisting.getValue(), existingImage]);
                        });
                    });
                });
            }
        });
    }

    public ngAfterContentInit() {
        this.subscriptions$.add(this.files.subscribe((files) => {
            if(this.imageType === 'image') {
                this.registrationService.state.imagesFileList.next(files);
                console.log('updating registrationService.state.imagesFileList', files);
            } else {
                this.registrationService.state.menuFileList.next(files);
                console.log('updating registrationService.state.menuFileList', files);
            }
        }));
        this.getRestaurantPics();
    }

    public ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    public onRemoveExisting(event) {
        console.log('onRemoveExisting', event);
        if(event.id) {
            if(this.imageType === 'image') {
                this.registrationService.deleteRestaurantPhoto(event.id).then((resp) => {
                    console.log('deleteRestaurantPhoto', resp);
                    this.setup();
                    this.getRestaurantPics();
                }).catch(() => {});
            } else {
                this.registrationService.deleteMenu(event.id).then((resp) => {
                    console.log('deleteMenu', resp);
                    this.setup();
                    this.getRestaurantPics();
                }).catch(() => {});
            }
        } else {
            this.messageService.add({
                severity:'error',
                summary: this.translate.instant('restaurant-edit.msg-image-delete-error-heading'),
                detail: this.translate.instant('restaurant-edit.msg-image-delete-error-text')
            });
        }
    }

    public onRemove(event) {
        const currentFiles = this.files.getValue().filter((file) =>
            // @ts-ignore
            event.file.objectURL.changingThisBreaksApplicationSecurity !== file.objectURL.changingThisBreaksApplicationSecurity);
        this.files.next(currentFiles);
        console.log('onRemove', this.files);
    }

    public onUpload(event) {
        this.files.next(event.files);
        if(this.imageType === 'image') {
            this.registrationService.uploadRestaurantImage(event.files).then((resp) => {
                console.log('uploadRestaurantImage', resp);
                this.setup();
                this.getRestaurantPics();
            }).catch(() => {});
        } else {
            this.registrationService.uploadRestaurantMenu(event.files).then((resp) => {
                console.log('uploadRestaurantMenu', resp);
                this.setup();
                this.getRestaurantPics();
            }).catch(() => {});
        }
    }

    public changeOrderExisting(event) {
        console.log('changeOrderExisting', event);
    }
    public changeOrder(event) {
        // const currentFiles = this.files.getValue();
        // const foundIndex = currentFiles.findIndex((file) => {
        //     // @ts-ignore
        //     if (event.file.objectURL.changingThisBreaksApplicationSecurity === file.objectURL.changingThisBreaksApplicationSecurity) {
        //         return true;
        //     }
        // });
        // if (event.direction === 'up') {
        //     if (foundIndex === 0) {
        //         return;
        //     }
        //     this.restaurantsService.moveInArray(currentFiles, foundIndex, foundIndex - 1);
        //     this.files.next([]);
        //     setTimeout(() => {
        //         this.files.next(currentFiles);
        //     }, 0);
        // }
        // if (event.direction === 'down') {
        //     if (foundIndex === currentFiles.length - 1) {
        //         return;
        //     }
        //     this.restaurantsService.moveInArray(currentFiles, foundIndex, foundIndex + 1);
        //     this.files.next([]);
        //     setTimeout(() => {
        //         this.files.next(currentFiles);
        //     }, 0);
        // }
    }


}
