import { Injectable } from '@angular/core';
import {
    BusinessHour,
    CountriesQueryService,
    CreateBusinessHoursInput,
    CreateBusinessHoursMutationService,
    CreateRestaurantInput,
    CreateRestaurantMutationService,
    DeleteMenuMutationService,
    DeleteMenuMutationVariables,
    DeleteOwnerMutationService,
    DeleteOwnerMutationVariables,
    DeleteRestaurantPhotoMutationService,
    DeleteRestaurantPhotoMutationVariables,
    MenuMultipleUploadMutationService,
    MeQueryService,
    OwnerInput,
    RegisterOwnerInput,
    RegisterOwnerMutationService,
    RestaurantPhotoMultipleUploadMutationService,
    UpdateBusinessHoursInput,
    UpdateOwnerMutationService,
    UpdatePasswordInput,
    UpdatePasswordMutationService,
    UpdateRestaurantMutationService
} from '../generated/graphql';
import { StorageService } from './ionic-storage.service';
import { BehaviorSubject } from 'rxjs';

export interface openingTimeEntry {
    day: string;
    dayOfWeek: number;
    isEnabled: boolean;
    openingTime: string;
    closingTime: string;
}

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {

    public state = {
        restaurantDetails: {
            name: new BehaviorSubject(''),
            streetName: new BehaviorSubject(''),
            streetNumber: new BehaviorSubject(''),
            postalCode: new BehaviorSubject(''),
            cityName: new BehaviorSubject(''),
            price: new BehaviorSubject(1),
            minArrivingTime: new BehaviorSubject(''),
            phone: new BehaviorSubject(''),
            extraInfoNL: new BehaviorSubject(''),
            extraInfoEN: new BehaviorSubject(''),
            categories: new BehaviorSubject(''),
        },
        imagesFileList: new BehaviorSubject<File[] | null>(null),
        menuFileList: new BehaviorSubject<File[] | null>(null),
        openingTimes: new BehaviorSubject<BusinessHour[]>([]),
        userId: new BehaviorSubject(''),
        restaurantId: new BehaviorSubject(''),
        token: new BehaviorSubject(''),
        fcmToken: new BehaviorSubject(''),
    };

    constructor(
        private countryQueryService: CountriesQueryService,
        private meQueryService: MeQueryService,
        private updatePasswordMutationService: UpdatePasswordMutationService,
        private registerOwnerMutationService: RegisterOwnerMutationService,
        private updateOwnerMutationService: UpdateOwnerMutationService,
        private createRestaurantMutationService: CreateRestaurantMutationService,
        private updateRestaurantMutationService: UpdateRestaurantMutationService,
        private createBusinessHoursMutationService: CreateBusinessHoursMutationService,
        private restaurantPhotoMultipleUploadMutationService: RestaurantPhotoMultipleUploadMutationService,
        private menuMultipleUploadMutationService: MenuMultipleUploadMutationService,
        private deleteOwnerMutationService: DeleteOwnerMutationService,
        private deleteRestaurantPhotoMutationService: DeleteRestaurantPhotoMutationService,
        private deleteMenuMutationService: DeleteMenuMutationService,
        private storageService: StorageService
    ) {
        this.init().then();
    }

    public deleteAccount(id: DeleteOwnerMutationVariables) {
        return this.deleteOwnerMutationService.mutate({
            id
        }, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.getValue()}`
                }
            }
        }).toPromise();
    }

    public getCountries() {
        return this.countryQueryService.fetch(null, {
            fetchPolicy: 'network-only',
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.getValue()}`
                }
            }
        }).toPromise();
    }

    public getMe() {
        return this.meQueryService.fetch(null, {
            fetchPolicy: 'network-only',
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.getValue()}`
                }
            }
        }).toPromise();
    }

    public updatePassword(input: UpdatePasswordInput) {
        return this.updatePasswordMutationService.mutate({
            input
        }, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.getValue()}`
                }
            }
        }).toPromise();
    }

    public createBusinessHours() {
        const businessHours: CreateBusinessHoursInput[] = this.state.openingTimes.getValue().map((entry) => ({
            closeTime: entry.closeTime,
            dayOfWeek: Number(entry.dayOfWeek),
            openTime: entry.openTime,
        } as CreateBusinessHoursInput));
        return this.createBusinessHoursMutationService.mutate(
            {
                input: businessHours,
                restaurant: {
                    connect: this.state.restaurantId.getValue(),
                }
            },
            {
                context: {
                    headers: {
                        'Accept-Language': localStorage.getItem('language') || 'nl',
                        Authorization: `Bearer ${this.state.token.getValue()}`
                    }
                }
            }
        ).toPromise();
    }

    public registerOwner(input: RegisterOwnerInput) {
        return this.registerOwnerMutationService.mutate({input}).toPromise().then((response) => {
            this.saveUserIdToStorage(response.data.registerOwner.user.id);
            this.saveAuthTokenToStorage(response.data.registerOwner.token);
            this.saveFcmTokenToStorage(response.data.registerOwner.fcmToken);
        });
    };

    public updateOwner(input: OwnerInput, id: string) {
        return this.updateOwnerMutationService.mutate({
            input,
            id
        }, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.getValue()}`
                }
            }
        }).toPromise();
    }

    public createRestaurant() {
        const input = {
            name: this.state.restaurantDetails.name.getValue(),
            streetName: this.state.restaurantDetails.streetName.getValue(),
            streetNumber: this.state.restaurantDetails.streetNumber.getValue(),
            postalCode: this.state.restaurantDetails.postalCode.getValue(),
            cityName: this.state.restaurantDetails.cityName.getValue(),
            price: Number(this.state.restaurantDetails.price.getValue()),
            phone: this.state.restaurantDetails.phone.getValue(),
            categories: {
                connect: [this.state.restaurantDetails.categories.getValue()]
            },
            owner: {
                connect: this.state.userId.getValue()
            },
            extraInfo: {
                en: this.state.restaurantDetails.extraInfoEN.getValue(),
                nl: this.state.restaurantDetails.extraInfoNL.getValue(),
            },
            minArrivingTime: 15,
        } as CreateRestaurantInput;

        return this.createRestaurantMutationService.mutate({input: {...input}}, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.getValue()}`
                },
            }
        }).toPromise().then((res) => {
            this.saveRestaurantIdToStorage(res.data.createRestaurant.id);
        });

    };

    public updateRestaurant(activeSection: string) {
        if(activeSection==='restaurant' || activeSection==='hours'){
            const id = this.state.restaurantId.getValue();
            const businessHours: UpdateBusinessHoursInput[] = this.state.openingTimes.getValue().map((entry) => ({
                id: entry.id,
                closeTime: entry.closeTime,
                dayOfWeek: Number(entry.dayOfWeek),
                openTime: entry.openTime,
            } as UpdateBusinessHoursInput));

            return this.updateRestaurantMutationService.mutate({
                input: {
                    name: this.state.restaurantDetails.name.getValue(),
                    category: {
                        connect: this.state.restaurantDetails.categories.getValue()
                    },
                    phone: this.state.restaurantDetails.phone.getValue(),
                    streetName: this.state.restaurantDetails.streetName.getValue(),
                    streetNumber: this.state.restaurantDetails.streetNumber.getValue(),
                    postalCode: this.state.restaurantDetails.postalCode.getValue(),
                    cityName: this.state.restaurantDetails.cityName.getValue(),
                    price: Number(this.state.restaurantDetails.price.getValue()),
                    businessHours,
                    restaurant: {
                        connect: id,
                    },
                    extraInfo: {
                        en: this.state.restaurantDetails.extraInfoEN.getValue(),
                        nl: this.state.restaurantDetails.extraInfoNL.getValue(),
                    },
                },
            }, {
                context : {
                    headers: {
                        'Accept-Language': localStorage.getItem('language') || 'nl',
                        Authorization: `Bearer ${this.state.token.getValue()}`
                    }
                },
            }).toPromise();
        }
    }

    public uploadRestaurantMenu(images: File[]) {
        return this.menuMultipleUploadMutationService.mutate({
            input: {
                attachment: images,
                restaurant: {
                    connect: this.state.restaurantId.getValue()
                }
            }
        }, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.getValue()}`
                }
            }
        }).toPromise();
    }

    public uploadRestaurantImage(images: File[]) {
        return this.restaurantPhotoMultipleUploadMutationService.mutate({
            input: {
                image: images,
                restaurant: {
                    connect: this.state.restaurantId.getValue()
                }
            }
        }, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.getValue()}`
                }
            }
        }).toPromise();
    }

    public uploadRestaurantMenus() {
        const images = this.state.menuFileList.getValue();
        return this.menuMultipleUploadMutationService.mutate({
            input: {
                attachment: images,
                restaurant: {
                    connect: this.state.restaurantId.getValue()
                }
            }
        }, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.getValue()}`
                }
            }
        }).toPromise();
    }

    public uploadRestaurantImages() {
        const images = this.state.imagesFileList.getValue();
        return this.restaurantPhotoMultipleUploadMutationService.mutate({
            input: {
                image: images,
                restaurant: {
                    connect: this.state.restaurantId.getValue()
                }
            }
        }, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.getValue()}`
                }
            }
        }).toPromise();
    }

    public deleteMenu(id: DeleteMenuMutationVariables) {
        return this.deleteMenuMutationService.mutate({
            id
        }, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.getValue()}`
                }
            }
        }).toPromise();
    }

    public deleteRestaurantPhoto(id: DeleteRestaurantPhotoMutationVariables) {
        return this.deleteRestaurantPhotoMutationService.mutate({
            id
        }, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.getValue()}`
                }
            }
        }).toPromise();
    }

    public saveAuthTokenToStorage(token: string) {
        this.state.token.next(token);
        if (token === '') {
            return this.storageService.remove('token');
        } else {
            return this.storageService.set('token', token);
        }
    }

    public saveUserIdToStorage(id: string) {
        this.state.userId.next(id);
        if (id === '') {
            return this.storageService.remove('userId');
        } else {
            return this.storageService.set('userId', id);
        }
    }

    public saveFcmTokenToStorage(fcmToken: string) {
        this.state.fcmToken.next(fcmToken);
        if (fcmToken === '') {
            return this.storageService.remove('fcmToken');
        } else {
            this.storageService.set('fcmToken', fcmToken);
        }
    }

    public saveRestaurantIdToStorage(id: string) {
        this.state.restaurantId.next(id);
        if (id === '') {
            return this.storageService.remove('restaurantId');
        } else {
            return this.storageService.set('restaurantId', id);
        }
    }

    public resetState() {
        this.state = {
            restaurantDetails: {
                name: new BehaviorSubject(''),
                streetName: new BehaviorSubject(''),
                streetNumber: new BehaviorSubject(''),
                postalCode: new BehaviorSubject(''),
                cityName: new BehaviorSubject(''),
                price: new BehaviorSubject(1),
                minArrivingTime: new BehaviorSubject(''),
                phone: new BehaviorSubject(''),
                categories: new BehaviorSubject(''),
                extraInfoNL: new BehaviorSubject(''),
                extraInfoEN: new BehaviorSubject(''),
            },
            imagesFileList: new BehaviorSubject<File[] | null>(null),
            menuFileList: new BehaviorSubject<File[] | null>(null),
            openingTimes: new BehaviorSubject<BusinessHour[]>([]),
            userId: new BehaviorSubject(''),
            restaurantId: new BehaviorSubject(''),
            token: new BehaviorSubject(''),
            fcmToken: new BehaviorSubject(''),
        };
        localStorage.clear();
        this.storageService.clear();
    }

    private async init() {
        this.storageService.get('restaurantId').then((result) => {
            if (result) {
                this.state.restaurantId.next(result);
            }
        });
        this.storageService.get('userId').then((result) => {
            if (result) {
                this.state.userId.next(result);
            }
        });
        this.storageService.get('token').then((result) => {
            if (result) {
                this.state.token.next(result);
            }
        });
        this.storageService.get('fcmToken').then((result) => {
            if (result) {
                this.state.fcmToken.next(result);
            }
        });
    }
}
