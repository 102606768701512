import { Injectable } from '@angular/core';
import {
    AvailableTables,
    AvailableTablesQueryService,
    CreateTableInput,
    CreateTableMutationService,
    DeleteTableInput,
    DeleteTableMutationService,
} from '../generated/graphql';
import { BehaviorSubject } from 'rxjs';
import { RegistrationService } from './registration.service';

@Injectable({
    providedIn: 'root',
})
export class TablesService {

    public state = {
        availableTables: new BehaviorSubject<AvailableTables[]>([]),
        amountOfTables: new BehaviorSubject<number>(0),
    };

    constructor(
        private registrationService: RegistrationService,
        private createTableMutationService: CreateTableMutationService,
        private deleteTableMutationService: DeleteTableMutationService,
        private availableTablesQueryService: AvailableTablesQueryService,
    ) {
    }

    public handleTotalFreeTables(availableTables: AvailableTables[]) {
        let total = 0;
        availableTables.forEach((table) => {
            total += table.count;
        });
        this.state.amountOfTables.next(total);
    }

    public getAvailableTables(restaurantId: string) {
        return this.availableTablesQueryService.fetch(
            { restaurantId },
            {
                fetchPolicy: 'network-only',
                context: {
                    headers: {
                        'Accept-Language': localStorage.getItem('language') || 'nl',
                        Authorization: `Bearer ${ this.registrationService.state.token.getValue() }`,
                    },
                },
            }).toPromise().then((res) => {
            if (res.data && res.data.availableTables) {
                this.state.availableTables.next(res.data.availableTables);
                this.handleTotalFreeTables(res.data.availableTables);
            }
        });
    }

    public deleteTable(input: DeleteTableInput) {
        return this.deleteTableMutationService.mutate(
            { input },
            {
                context: {
                    headers: {
                        'Accept-Language': localStorage.getItem('language') || 'nl',
                        Authorization: `Bearer ${ this.registrationService.state.token.getValue() }`,
                    },
                },
            }).toPromise().then((res) => {
            this.getAvailableTables(input.restaurant.connect);
        });
    }

    public createTable(input: CreateTableInput) {
        return this.createTableMutationService.mutate(
            { input },
            {
                context: {
                    headers: {
                        'Accept-Language': localStorage.getItem('language') || 'nl',
                        Authorization: `Bearer ${ this.registrationService.state.token.getValue() }`,
                    },
                },
            }).toPromise().then((res) => {
            this.getAvailableTables(input.restaurant.connect).then(() => {
            });
        });
    }


}
