import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/ionic-storage.service';

@Injectable({
    providedIn: 'root'
})
export class HasNoCredentialsGuard implements CanActivate {

    constructor(
        private storageService: StorageService,
        private router: Router,
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return Promise.all([
            this.storageService.get('restaurantId'),
            this.storageService.get('token'),
            this.storageService.get('userId')
        ]).then((result) => {
            const found = result.filter((res) => res !== null);

            if (found.length > 0) {
                this.router.navigate(['table-availability']);
            } else {
                return true;
            }
        });
    }

}
