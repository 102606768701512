import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

interface BookingPeriod {
    period: string;
};

interface UpdateFrequency {
    frequency: string;
};

@Component({
    selector: 'app-c-settings-form',
    templateUrl: './c-settings-form.component.html',
    styleUrls: ['./c-settings-form.component.scss'],
})


export class CSettingsFormComponent implements OnInit {

    period: BookingPeriod[];
    selectedPeriod: BookingPeriod;
    frequency: UpdateFrequency[];
    selectedUpdateFrequency: UpdateFrequency;


    constructor() {
        this.period = [
            {period: '1 hour'},
            {period: '2 hours'},
            {period: '3 hours'},
        ];
        this.frequency = [
            {frequency: '1 hour'},
            {frequency: '2 hours'},
            {frequency: '3 hours'},
        ];
    }


    ngOnInit() {

    }

}
