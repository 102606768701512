import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RestaurantsService } from '../../../services/restaurants.service';
import { Subscription } from 'rxjs';
import { RegistrationService } from '../../../services/registration.service';
import { StorageService } from '../../../services/ionic-storage.service';

interface Category {
    id: string;
    name: string;
};

@Component({
    selector: 'app-c-restaurant-details',
    templateUrl: './c-restaurant-details.component.html',
    styleUrls: ['./c-restaurant-details.component.scss'],
})
export class CRestaurantDetailsComponent implements OnInit, OnDestroy {
    @Output() formIsValid = new EventEmitter<boolean>();

    public priceIndications: string[] = [];
    public categories: Category[];
    public subscription = new Subscription();
    public formGroup: FormGroup;
    public isLoggedIn = false;

    constructor(
        private formBuilder: FormBuilder,
        private restaurantsService: RestaurantsService,
        private registrationService: RegistrationService,
        private storageService: StorageService,
    ) {
    }

    public ngOnInit(): void {
        this.priceIndications = this.restaurantsService.priceIndications();
        this.restaurantsService.getCategories().then((response) => {
            this.categories = response.data.categories.map((category) => ({name: category.name, id: category.id}));
        });
        this.storageService.get('restaurantId').then((id) => {
            if (id) {
                this.restaurantsService.getRestaurantById(id).then((res) => {
                    const restaurant = res.data.restaurantById;
                    this.formGroup.setValue({
                        name: restaurant.name,
                        streetName: restaurant.streetName,
                        streetNumber: restaurant.streetNumber,
                        postalCode: restaurant.postalCode,
                        cityName: restaurant.cityName,
                        price: restaurant.price,
                        telephone: restaurant.phone,
                        extraInfoNL: restaurant.extraInfo.nl,
                        extraInfoEN: restaurant.extraInfo.en,
                        selectedCategory: restaurant.categories[0].id,
                    });
                });
            }
        });

        this.formGroup = this.formBuilder.group({
            name: ['', Validators.required],
            streetName: ['', Validators.required],
            streetNumber: ['', Validators.required],
            postalCode: ['', Validators.required],
            cityName: ['', Validators.required],
            price: [1],
            telephone: ['', [Validators.required, Validators.minLength(10)]],
            selectedCategory: ['', Validators.required],
            extraInfoNL: [''],
            extraInfoEN: [''],
        });


        this.subscription.add(this.formGroup.valueChanges.subscribe((formValue) => {
            this.registrationService.state.restaurantDetails.name.next(formValue.name);
            this.registrationService.state.restaurantDetails.streetName.next(formValue.streetName);
            this.registrationService.state.restaurantDetails.streetNumber.next(formValue.streetNumber);
            this.registrationService.state.restaurantDetails.postalCode.next(formValue.postalCode);
            this.registrationService.state.restaurantDetails.cityName.next(formValue.cityName);
            this.registrationService.state.restaurantDetails.price.next(formValue.price);
            this.registrationService.state.restaurantDetails.phone.next(formValue.telephone);
            this.registrationService.state.restaurantDetails.extraInfoEN.next(formValue.extraInfoEN);
            this.registrationService.state.restaurantDetails.extraInfoNL.next(formValue.extraInfoNL);
            // eslint-disable-next-line max-len
            this.registrationService.state.restaurantDetails.categories.next(formValue.selectedCategory);
            this.formIsValid.emit(this.formGroup.valid);
            console.log('categories', this.registrationService.state.restaurantDetails.categories.getValue());
        }));
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
