import { Component, Input, OnInit } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-c-establishment-items',
    templateUrl: './c-establishment-items.component.html',
    styleUrls: ['./c-establishment-items.component.scss'],
})
export class CEstablishmentItemsComponent implements OnInit {
public establishments = this.getEstablishments();

constructor() { }

ngOnInit() {}

public getEstablishments() {
    return [
        {
            id: 'UI23-23DD-213D-V311',
            image: 'http://www.picsum.photos/80/80',
            title: 'Restaurant naam hier',
            location: 'teststraat 14, Haarlem',
            distance: '??',
            rating: '??',
            telephoneNumber: '012345678',
            status: 'CLAIMABLE',
            amountOfPersons: '??',
            time: '??',
        },
        {
            id: 'UI23-23DD-213D-V322',
            image: 'http://www.picsum.photos/80/80',
            title: 'Restaurant naam hier',
            location: 'teststraat 14, Haarlem',
            distance: '??',
            rating: '??',
            telephoneNumber: '012345678',
            status: 'CLAIMED',
            amountOfPersons: '??',
            time: '??',
        },
        {
            id: 'UI23-23DD-213D-V333',
            image: 'http://www.picsum.photos/80/80',
            title: 'Restaurant naam hier',
            location: 'teststraat 14, Haarlem',
            distance: '??',
            rating: '??',
            telephoneNumber: '012345678',
            status: 'FULL',
            amountOfPersons: '??',
            time: '??',
        },
        {
            id: 'UI23-23DD-213D-V344',
            image: 'http://www.picsum.photos/80/80',
            title: 'Restaurant naam hier',
            location: 'teststraat 14, Haarlem',
            distance: '??',
            rating: '??',
            telephoneNumber: '012345678',
            status: 'WAITINGLIST',
            amountOfPersons: '??',
            time: '??',
        }
    ];
}

public handleEstablishmentDetails(childData) {
    console.log('sendGraphql', childData);
}
}
