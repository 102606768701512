import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountActionsService } from '../../../services/account-actions.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-c-new-password-form',
    templateUrl: './c-new-password-form.component.html',
    styleUrls: ['./c-new-password-form.component.scss'],
})
export class CNewPasswordFormComponent implements OnInit {

    public newPasswordForm: FormGroup;
    public subscriptions$ = new Subscription();
    public passwordType = 'password';


    constructor(
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private router: Router,
        private accountActionsService: AccountActionsService,
        private activatedRoute: ActivatedRoute,
        private messageService: MessageService,
    ) {
    }

    public ngOnInit() {
        this.newPasswordForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(8)]],
        });
    }

    public togglePassword() {
        if (this.passwordType === 'password') {
            this.passwordType = 'text';
        } else {
            this.passwordType = 'password';
        }
    }

    public handleNewPassword() {
        if (this.newPasswordForm.valid) {
            this.subscriptions$.add(this.activatedRoute.queryParams.subscribe((params) => {
                console.log(params);
                this.accountActionsService.resetPassword({
                    password: this.newPasswordForm.controls.password.value,
                    email: params.email,
                    token: params.token,
                }).then(() => {
                    this.messageService.add({
                        severity: 'info',
                        summary: this.translate.instant('new-password.update-heading'),
                        detail: this.translate.instant('new-password.update-text')
                    });
                    this.router.navigate(['/home'])
                        .then(nav => {
                            console.log(nav);
                        }, err => {
                            console.log(err);
                        });
                });
            }));
        }
    }
}
