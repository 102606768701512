import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-c-popup',
    templateUrl: './c-popup.component.html',
    styleUrls: ['./c-popup.component.scss'],
})
export class CPopupComponent implements OnInit {

  @Input()
  public title = '';

  constructor() { }

  ngOnInit() {}

}
