import { Injectable } from '@angular/core';
import {
    CurrentRestaurantClaimsQueryService, HistoryRestaurantClaimsQueryService,
    RejectClaimTableMutationService
} from '../generated/graphql';
import { RegistrationService } from './registration.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GuestsService {

    public state = {
        amountOfGuests: new BehaviorSubject<number>(0),
    };

    constructor(
        private currentRestaurantClaimsQueryService: CurrentRestaurantClaimsQueryService,
        private historyRestaurantClaimsQueryService: HistoryRestaurantClaimsQueryService,
        private rejectClaimTableMutationService: RejectClaimTableMutationService,
        private registrationService: RegistrationService
    ) {
    }

    public getCurrentRestaurantClaims() {
        return this.currentRestaurantClaimsQueryService.fetch(
            {
                id: this.registrationService.state.restaurantId.getValue()
            },
            {
                context: {
                    headers: {
                        'Accept-Language': localStorage.getItem('language') || 'nl',
                        Authorization: `Bearer ${this.registrationService.state.token.getValue()}`
                    },
                }
            }).toPromise().then((res) => {
            if(res.data && res.data.restaurantClaims && res.data.restaurantClaims.data) {
                this.state.amountOfGuests.next(res.data.restaurantClaims.data.length);
            }
            return res;
        });
    }

    public getHistoryRestaurantClaims(date: Date) {
        return this.historyRestaurantClaimsQueryService.fetch(
            {
                id: this.registrationService.state.restaurantId.getValue(),
                date
            },
            {
                context: {
                    headers: {
                        'Accept-Language': localStorage.getItem('language') || 'nl',
                        Authorization: `Bearer ${this.registrationService.state.token.getValue()}`
                    },
                }
            }).toPromise();
    }

    public rejectClaim(tableId: string) {
        return this.rejectClaimTableMutationService.mutate({
            input: {
                table: {connect: tableId},
                owner: {connect: this.registrationService.state.userId.getValue()}
            }
        }, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.registrationService.state.token.getValue()}`
                },
            }
        }).toPromise();
    }
}
