import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { Table } from '../../../generated/graphql';
import { RegistrationService } from '../../../services/registration.service';
import { GuestsService } from '../../../services/guests.service';
import * as moment from 'moment';
import { Moment } from 'moment';

interface DateObject {
    label: string;
    value: Moment;
};

@Component({
    selector: 'app-c-guests-history',
    templateUrl: './c-guests-history.component.html',
    styleUrls: ['./c-guests-history.component.scss'],
})
export class CGuestsHistoryComponent implements OnInit, OnDestroy {


    public historyTables$: BehaviorSubject<Table[]> = new BehaviorSubject([]);
    public subscriptions$ = new Subscription();
    public dates: DateObject[] = [
        {label: moment().subtract(1, 'day').format('DD MMMM yyyy'), value: moment().subtract(1, 'day')},
        {label: moment().subtract(2, 'day').format('DD MMMM yyyy'), value: moment().subtract(2, 'day')},
        {label: moment().subtract(3, 'day').format('DD MMMM yyyy'), value: moment().subtract(3, 'day')},
        {label: moment().subtract(4, 'day').format('DD MMMM yyyy'), value: moment().subtract(4, 'day')},
        {label: moment().subtract(5, 'day').format('DD MMMM yyyy'), value: moment().subtract(5, 'day')},
        {label: moment().subtract(6, 'day').format('DD MMMM yyyy'), value: moment().subtract(6, 'day')},
        {label: moment().subtract(7, 'day').format('DD MMMM yyyy'), value: moment().subtract(7, 'day')},
    ];
    public selectedDate: DateObject = this.dates[0];

    constructor(
        private registrationService: RegistrationService,
        private guestsService: GuestsService
    ) {
    }

    ngOnInit() {
        this.getHistoryClaims();
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    public getHistoryClaims() {

        this.subscriptions$.add(combineLatest([
            this.registrationService.state.token,
            this.registrationService.state.restaurantId
        ]).subscribe((combined) => {
            if (combined[0] !== '' && combined[1] !== '') {
                const selectedDate = this.selectedDate.value.format('yyyy-MM-DD');
                // @ts-ignore
                this.guestsService.getHistoryRestaurantClaims(selectedDate).then((response) => {
                    this.historyTables$.next(response.data.restaurantClaims.data as Table[]);
                });
            }
        }));
    }

}
