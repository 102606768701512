import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[appALabel]',
})

export class ALabelDirective {

  @Input() color: 'dark' |'grey' | 'red' | 'white' | 'blue' | '' = '';
  @Input() isThin = false;
  @Input() isLarge = false;
  @Input() hasBackground = false;


  @HostBinding('class') get classes(): string {
      const classes: Array<string> = [];

      classes.push('a-label');
      if(this.color) {classes.push(`a-label--${this.color}`);}
      if(this.isThin) {classes.push(`a-label--isThin`);}
      if(this.isLarge) {classes.push(`a-label--isLarge`);}
      if(this.hasBackground) {classes.push(`a-label--hasBackground`);}


      return classes.join(' ');
  }

}
