import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

import { Events } from './events.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    selected = 'nl';

    constructor(
        private translate: TranslateService,
        private events: Events
    ) {
        this.setInitialAppLanguage();
        this.subscribeToEvents();
    };

    setInitialAppLanguage() {
        this.translate.addLangs(['en', 'nl']);
        const lng = localStorage.getItem('language') || this.translate.getBrowserLang();
        this.setLanguage(lng);
    }

    setLanguage(lng: string) {
        this.translate.setDefaultLang(lng);
        this.translate.use(lng);
        this.selected = lng;
        localStorage.setItem('language', lng);
    }

    subscribeToEvents() {
        this.events.subscribe('language:reload', () => {
            const language = localStorage.getItem('language') || this.translate.getBrowserLang();
            this.setLanguage(language);
        });
    }
}
