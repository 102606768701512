import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MustBeLoggedInGuard } from './guards/must-be-logged-in-guard.service';
import { HasNoCredentialsGuard } from './guards/has-no-credentials.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        canActivate: [HasNoCredentialsGuard],
        loadChildren: () => import('./pages/onboarding-start/onboarding-start.module').then(m => m.OnboardingStartPageModule)
    },
    {
        path: 'login',
        canActivate: [HasNoCredentialsGuard],
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: 'password-reset',
        canActivate: [HasNoCredentialsGuard],
        loadChildren: () => import('./pages/password-reset/password-reset.module').then(m => m.PasswordResetPageModule)
    },
    {
        path: 'new-password',
        canActivate: [HasNoCredentialsGuard],
        loadChildren: () => import('./pages/new-password/new-password.module').then(m => m.NewPasswordPageModule)
    },
    {
        path: 'registration',
        canActivate: [HasNoCredentialsGuard],
        loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationPageModule)
    },
    {
        path: 'my-guests',
        canActivate: [MustBeLoggedInGuard],
        loadChildren: () => import('./pages/my-guests/my-guests.module').then(m => m.MyGuestsPageModule)
    },
    {
        path: 'molecules',
        loadChildren: () => import('./pages/molecules/molecules.module').then(m => m.MoleculesPageModule)
    },
    {
        path: 'restaurant-details',
        canActivate: [HasNoCredentialsGuard],
        loadChildren: () => import('./pages/restaurant-details/restaurant-details.module').then(m => m.RestaurantDetailsPageModule)
    },
    {
        path: 'email-notification',
        canActivate: [HasNoCredentialsGuard],
        loadChildren: () => import('./pages/email-notification/email-notification.module').then(m => m.EmailNotificationPageModule)
    },
    {
        path: 'settings',
        canActivate: [MustBeLoggedInGuard],
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
    },
    {
        path: 'my-account',
        canActivate: [MustBeLoggedInGuard],
        loadChildren: () => import('./pages/my-account/my-account.module').then(m => m.MyAccountPageModule)
    },
    {
        path: 'change-password',
        canActivate: [MustBeLoggedInGuard],
        loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
    },
    {
        path: 'table-availability',
        canActivate: [MustBeLoggedInGuard],
        loadChildren: () => import('./pages/table-availability/table-availability.module').then(m => m.TableAvailabilityPageModule)
    },
    {
        path: 'my-profile',
        canActivate: [MustBeLoggedInGuard],
        loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfilePageModule)
    },
    {
        path: 'restaurant-edit',
        canActivate: [MustBeLoggedInGuard],
        loadChildren: () => import('./pages/restaurant-edit/restaurant-edit.module').then(m => m.RestaurantEditPageModule)
    },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
