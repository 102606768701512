import { Injectable } from '@angular/core';
import { CategoriesQueryService, RestaurantByIdQueryService, RestaurantsQueryService } from '../generated/graphql';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class RestaurantsService {

    public state = {
        currentRestaurantId: new BehaviorSubject('588be519-5428-4278-8815-a96b4a17ece5'),
    };

    constructor(
        private translate: TranslateService,
        private restaurantsQueryService: RestaurantsQueryService,
        private restaurantByIdQueryService: RestaurantByIdQueryService,
        private categoriesQueryService: CategoriesQueryService,
    ) {
    }

    getRestaurants() {
        return this.restaurantsQueryService.fetch(
            {
                input: {
                    address: 'haarlem',
                    first: 1,
                    numOfPersons: 2,
                    page: 1,
                    radius: 4,
                },
            }, {
                context: {
                    headers: {
                        'Accept-Language': localStorage.getItem('language') || 'nl',
                    },
                },
            },
        ).toPromise();
    }

    getRestaurantById(id: string) {
        return this.restaurantByIdQueryService.fetch(
            {
                input: {
                    numOfPersons: 2,
                    coordinates: {
                        latitude: 52.4346897,
                        longitude: 4.6588708,
                    },
                    id,
                },
            },
            {
                context: {
                    headers: {
                        'Accept-Language': localStorage.getItem('language') || 'nl',
                    },
                },
            },
        ).toPromise();
    }

    getCategories() {
        return this.categoriesQueryService.fetch({},{
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                },
            },
        },
        ).toPromise();
    }

    getWeekDay(dayNum: number) {
        const days = {
            0: this.translate.instant('general.sunday'),
            1: this.translate.instant('general.monday'),
            2: this.translate.instant('general.tuesday'),
            3: this.translate.instant('general.wednesday'),
            4: this.translate.instant('general.thursday'),
            5: this.translate.instant('general.friday'),
            6: this.translate.instant('general.saturday'),
        };
        return days[dayNum];
    }

    getWeekDays() {
        return [
            { num: 1, day: this.translate.instant('general.monday') },
            { num: 2, day: this.translate.instant('general.tuesday') },
            { num: 3, day: this.translate.instant('general.wednesday') },
            { num: 4, day: this.translate.instant('general.thursday') },
            { num: 5, day: this.translate.instant('general.friday') },
            { num: 6, day: this.translate.instant('general.saturday') },
            { num: 0, day: this.translate.instant('general.sunday') },
        ];
    }

    priceIndications() {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const currentSymbol = timeZone.toLowerCase() === 'europe/amsterdam' ? '€' : '£';
        return [currentSymbol.repeat(1), currentSymbol.repeat(2), currentSymbol.repeat(3)];
    }

    priceIndication(price: number) {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const currentSymbol = timeZone.toLowerCase() === 'europe/amsterdam' ? '€' : '£';
        return currentSymbol.repeat(price);
    }
}
