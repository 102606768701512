import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface Table {
    tableCapacity: number;
    count: number;
}

@Component({
    selector: 'app-o-card-tables-capacity',
    templateUrl: './o-card-tables-capacity.component.html',
    styleUrls: ['./o-card-tables-capacity.component.scss'],
})
export class OCardTablesCapacityComponent {
    @Output() emitChange = new EventEmitter();
    @Input() public tables: Table[];

    public emitOnChange(childData) {
        console.log(childData);
        this.emitChange.emit(childData);
    }
}
