import { Component, OnDestroy, OnInit } from '@angular/core';
import { TablesService } from '../../../services/tables.service';
import { RegistrationService } from '../../../services/registration.service';
import { BehaviorSubject, concat, merge, Subscription } from 'rxjs';
import { AvailableTables, TableTypeEnum } from '../../../generated/graphql';
import { debounceTime, first, skip, take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-c-table-availability',
    templateUrl: './c-table-availability.component.html',
    styleUrls: ['./c-table-availability.component.scss'],
})
export class CTableAvailabilityComponent implements OnInit, OnDestroy {


    public currentRestaurantId$ = this.registrationService.state.restaurantId;
    public subscriptions$ = new Subscription();

    public tables = new BehaviorSubject([
        {tableCapacity: 1, count: 0},
        {tableCapacity: 2, count: 0},
        {tableCapacity: 3, count: 0},
        {tableCapacity: 4, count: 0},
        {tableCapacity: 5, count: 0},
        {tableCapacity: 6, count: 0},
        {tableCapacity: 8, count: 0},
        {tableCapacity: 10, count: 0},
        {tableCapacity: 12, count: 0},
        {tableCapacity: 16, count: 0},
    ]);

    constructor(
        private tablesService: TablesService,
        private registrationService: RegistrationService,
        private route: ActivatedRoute
    ) {
        route.params.subscribe(val => {
            this.getTables();
        });
    }

    public ngOnInit() {
        console.log('ngOnInit');
        this.getTables();
        this.subscriptions$.add(this.tablesService.state.availableTables.pipe(debounceTime(400)).subscribe((tables) => {
            this.updateTables(tables);
        }));
    }

    public ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    public getTables() {
        this.subscriptions$.add(this.registrationService.state.token.subscribe((id) => {
            if (id) {
                this.tablesService.getAvailableTables(this.currentRestaurantId$.getValue());
            }
        }));
    }

    public updateTables(availableTables: AvailableTables[]) {
        availableTables.forEach((table) => {
            this.tables.next(this.tables.getValue().map((localTable) => {
                if (localTable.tableCapacity === table.numOfPersons) {
                    localTable.count = table.count;
                }
                return localTable;
            }));
        });
    }

    public handleTableAvailability(childData) {
        if (childData.calculation === 'plus') {
            this.tablesService.createTable({
                type: TableTypeEnum.Inside,
                numOfPersons: childData.tableCapacity,
                restaurant: {
                    connect: this.currentRestaurantId$.getValue(),
                },
            });
        } else {
            this.tablesService.deleteTable({
                numOfPersons: childData.tableCapacity,
                restaurant: {
                    connect: this.currentRestaurantId$.getValue(),
                },
            });
        }
    }

}
