import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AccountActionsService } from '../../../services/account-actions.service';
import { Router } from '@angular/router';
import { Globals } from '../../../config/globals';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-m-my-account-popup',
    templateUrl: './my-account-popup.component.html',
    styleUrls: ['./my-account-popup.component.scss'],
})
export class MyAccountPopupComponent implements OnInit {

    @Output() toggleVisibility = new EventEmitter();
    public isHidden = false;
    public apiVersion: string;
    public appVersion: string;
    public faqURL: string;

    constructor(
        public translate: TranslateService,
        private accountActionsService: AccountActionsService,
        private router: Router,
        private iab: InAppBrowser,
    ) {
    }

    public ngOnInit() {
        this.apiVersion = localStorage.getItem('apiVersion');
        this.appVersion = Globals.config.APP_VERSION;
    }

    public handleToggleVisibility() {
        this.isHidden = !this.isHidden;
        this.toggleVisibility.emit();
    }

    public handleIsHidden(event) {
        this.isHidden = false;
    }

    public handleClick() {
        this.isHidden = true;
        this.toggleVisibility.emit();
    }

    public handleFaq() {
        this.handleClick();

        if (this.translate.currentLang === 'en') {
            this.faqURL = Globals.config.FAQ.en;
        } else if (this.translate.currentLang === 'nl') {
            this.faqURL = Globals.config.FAQ.nl;
        }
        const win = this.iab.create(this.faqURL, 'blank');
        win.show();
    }

    public handleLogout() {
        this.accountActionsService.logout().then(() => {
            this.router.navigate(['/']);
        });
    }
}
