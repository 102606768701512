import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';


@Component({
    selector: 'app-m-menu-bar',
    templateUrl: './m-menu-bar.component.html',
    styleUrls: ['./m-menu-bar.component.scss'],
})
export class MMenuBarComponent {
    @Input() items: {
        label: string;
        badge: BehaviorSubject<number>;
        icon: string;
        url: string;
    }[];

    public activeItem: MenuItem;

    constructor(
        private router: Router,
        private navCtrl: NavController,
    ) {
    }

    public isActive(item) {
        return item.url === this.router.url;
    }

    public async menuClick(url: string) {
        console.log('menuClick', url);
        this.navCtrl.setDirection('root');
        await this.router.navigateByUrl(url);
    }
}
