import { Component, OnInit } from '@angular/core';
import { TablesService } from '../../../services/tables.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RegistrationService } from '../../../services/registration.service';
import { GuestsService } from '../../../services/guests.service';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from '../../../interfaces/menu-item';

@Component({
    selector: 'app-c-menu-footer',
    templateUrl: './c-menu-footer.component.html',
    styleUrls: ['./c-menu-footer.component.scss'],
})
export class CMenuFooterComponent implements OnInit {

    public subscriptions$ = new Subscription();
    public currentRestaurantId$ = this.registrationService.state.restaurantId;
    public items: MenuItem[] = [];

    constructor(
        private tablesService: TablesService,
        private guestsService: GuestsService,
        private registrationService: RegistrationService,
        public translate: TranslateService,
    ) {
    }

    ngOnInit() {
        this.items = [
            {
                label: 'FreeTable',
                badge: this.tablesService.state.amountOfTables,
                icon: 'assets/images/svg/icon-table.svg',
                url: '/table-availability'
            },
            {
                label: this.translate.instant('menu.my-guests'),
                badge: this.guestsService.state.amountOfGuests,
                icon: 'assets/images/svg/icon-bell.svg',
                url: '/my-guests'
            },
            {
                label: this.translate.instant('menu.my-profile'),
                badge: new BehaviorSubject(null),
                icon: 'assets/images/svg/icon-restaurant.svg',
                url: '/my-profile'
            }
        ];
        this.subscriptions$.add(this.registrationService.state.token.subscribe((id) => {
            if (id && this.currentRestaurantId$.getValue()) {
                this.tablesService.getAvailableTables(this.currentRestaurantId$.getValue());
            }
        }));

        this.subscriptions$.add(this.registrationService.state.token.subscribe((id) => {
            if (id) {
                this.guestsService.getCurrentRestaurantClaims();
            }
        }));
    }
}
