import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegistrationService } from '../../../services/registration.service';
import { Subscription } from 'rxjs';
import { StorageService } from '../../../services/ionic-storage.service';
import { MessageService } from 'primeng/api';
import { LanguageService } from '../../../services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-c-my-account',
    templateUrl: './c-my-account.component.html',
    styleUrls: ['./c-my-account.component.scss'],
})
export class CMyAccountComponent implements OnInit {

    public myAccountForm: FormGroup;
    public subscription$ = new Subscription();
    public me: any;

    constructor(
        public translate: TranslateService,
        public languageService: LanguageService,
        private formBuilder: FormBuilder,
        private router: Router,
        private registrationService: RegistrationService,
        private storageService: StorageService,
        private messageService: MessageService,
    ) {
    }

    ngOnInit() {
        this.myAccountForm = this.formBuilder.group({
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            email: ['', [Validators.required]],
            phone: ['', [Validators.required]],
        });
        this.storageService.get('token').then((token) => {
            this.registrationService.getMe().then((response) => {
                this.me = response.data.me;
                this.setMe();
            });
        });
    }

    public setMe() {
        this.myAccountForm.setValue({
            first_name: this.me.firstName,
            last_name: this.me.lastName,
            email: this.me.email,
            phone: this.me.phone,
        });
    }

    public changeLanguage(event: any) {
        this.translate.use(event.detail.value);
        this.languageService.setLanguage(event.detail.value);
        this.myAccountForm.reset();
        this.setMe();
    }

    public handleLogin() {
        if (this.myAccountForm.valid) {
            this.registrationService.updateOwner(
                this.myAccountForm.value,
                this.registrationService.state.userId.getValue()
            ).then((response) => {
                this.messageService.add({
                    severity:'info',
                    summary: this.translate.instant('my-account.success-heading'),
                    detail: this.translate.instant('my-account.success-text')
                });
            }).catch(() => {
                this.messageService.add({
                    severity:'error',
                    summary: this.translate.instant('my-account.error-heading'),
                    detail: this.translate.instant('my-account.error-text')
                });
            });
        }
    }

}
