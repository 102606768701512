import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BusinessHour } from '../../../generated/graphql';

@Component({
    selector: 'app-m-opening-times',
    templateUrl: './m-opening-times.component.html',
    styleUrls: ['./m-opening-times.component.scss'],
})
export class MOpeningTimesComponent implements OnDestroy, OnInit {

    @Input() public day = '';
    @Input() public dayNumber: number;
    @Input() public openTime: string;
    @Input() public closeTime: string;
    @Input() public prefilled: BusinessHour;
    @Output() public openingTimeChanged = new EventEmitter();
    public checked = false;


    public formGroup: FormGroup;
    public subscription = new Subscription();
    public openingHoursOptions = [
        '0:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30',
        '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00',
        '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00',
        '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '00:00'
    ];

    constructor(
        private formBuilder: FormBuilder
    ) {
    }

    public setChecked(event): void {
        this.formGroup.controls.isEnabled.setValue(event.checked);
    }

    public ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            dayOfWeek: [this.dayNumber, Validators.required],
            isEnabled: [false],
            openTime: [this.openingHoursOptions[0], Validators.required],
            closeTime: [this.openingHoursOptions[0], Validators.required],
        });

        this.subscription.add(this.formGroup.valueChanges.subscribe((formGroup) => {
            this.openingTimeChanged.emit(formGroup);
        }));

        setTimeout(() => {
            if (this.prefilled) {
                this.checked = true;
                this.formGroup.controls.isEnabled.setValue(true, {onlySelf: true});
                this.formGroup.controls.openTime.setValue(this.prefilled.openTime, {onlySelf: true});
                this.formGroup.controls.closeTime.setValue(this.prefilled.closeTime, {onlySelf: true});
            }
        }, 1000);
        // TODO: this is a hack, @Joost we have to wait untill the prefilled is set before we do this,
        // i already tried behaviourSubject but i can't figure it out
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
