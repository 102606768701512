import { Injectable } from '@angular/core';
import { ApiVersionQueryService } from '../generated/graphql';
import { Globals } from '../config/globals';

@Injectable({
    providedIn: 'root'
})
export class ApiVersionService {
    public apiVersion = '';

    constructor(
        private apiVersionService: ApiVersionQueryService,
    ){}

    public async getApiVersion() {
        return this.apiVersionService.fetch().toPromise();
    }

    public checkApiVersion(apiVersion: string) {
        this.apiVersion = apiVersion;
        localStorage.setItem('apiVersion', this.apiVersion);
        const apiMajorVersion = Number(apiVersion.split('.')[0]);
        const localVersion = Number(Globals.config.LOCAL_VERSION.split('.')[0]);
        console.log('checkApiVersion', apiMajorVersion, localVersion);
        return apiMajorVersion > localVersion;
    }
}
