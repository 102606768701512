import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-save-changes-popup',
    templateUrl: './save-changes-popup.component.html',
    styleUrls: ['./save-changes-popup.component.scss'],
})
export class SaveChangesPopupComponent implements OnInit {

    constructor() { }

    ngOnInit() {}

}

