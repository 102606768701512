import { Component, EventEmitter, Input, Output } from '@angular/core';

//TODO: This has to come from the backend
const EstablishmentStatusEnum = {
    claimable: 'claimable',
    claimed: 'claimed',
    full: 'full',
    waiting: 'waiting_list',
    cancelled: 'cancelled',
    rejected: 'rejected',
};


@Component({
    selector: 'app-m-card-establishment-item',
    templateUrl: './m-card-establishment-item.component.html',
    styleUrls: ['./m-card-establishment-item.component.scss'],
})

export class MCardEstablishmentItemComponent {
    @Input() id: string;
    @Input() image = 'http://www.picsum.photos/80/80';
    @Input() title = 'Restaurant naam hier';
    @Input() location = 'Teststraat 14, Haarlem';
    @Input() distance = '??';
    @Input() rating = '??';
    @Input() telephoneNumber = '0123456789';
    @Input() status: 'CLAIMABLE' | 'CLAIMED' | 'FULL' | 'WAITINGLIST' = 'CLAIMABLE';

    @Input() amountOfPersons = '??';
    @Input() time = '??';
    @Output() emitChange = new EventEmitter();

    public EstablishmentStatusEnum = EstablishmentStatusEnum;

    public handleClaim() {
        this.emitChange.emit({
            id: this.id,
        });
    }

}
