import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { finalize, map, takeWhile } from 'rxjs/operators';
import { BehaviorSubject, interval, Subscription } from 'rxjs';

const EstablishmentStatusEnum = {
    claimed: 'claimed',
    rejected: 'rejected',
    pending: 'pending',
    cancelled: 'cancelled'
};

@Component({
    selector: 'app-m-card-guest-item',
    templateUrl: './m-card-guest-item.component.html',
    styleUrls: ['./m-card-guest-item.component.scss'],
})
export class MCardGuestItemComponent implements OnInit {

    @Input() firstName = 'Linda';
    @Input() lastName = 'Jansen';
    @Input() amountOfPersons = 0;
    @Input() time = '??';
    @Input() telephoneNumber = '012345678';
    @Input() status = 'CLAIMED';
    @Input() userId = '';
    @Input() tableId = '';

    @Output() emitHandleClaim = new EventEmitter();
    @Output() emitReject = new EventEmitter();
    @Output() emitChange = new EventEmitter();

    public EstablishmentStatusEnum = EstablishmentStatusEnum;
    public isClaimedRecently$ = new BehaviorSubject(false);
    public claimPercentage = 0;
    public subscriptions$ = new Subscription();

    constructor() {
    }

    ngOnInit() {
        const now = moment().add('0', 'seconds');
        const claimedAt = moment(this.time);
        const passedSeconds = this.passedSeconds(now, claimedAt);
        if (passedSeconds < 65) {
            this.isClaimedRecently$.next(true);
            this.subscriptions$.add(interval(1000).pipe(
                map((result) => result + passedSeconds),
                takeWhile((result) => result < 65),
                finalize(() => {
                    this.isClaimedRecently$.next(false);
                    this.emitChange.emit();
                }),
            ).subscribe((res) => {
                this.claimPercentage = (res / 65) * 100;
            }));
        }
    }

    public handleTime(time: string) {
        // console.log(time);
        return moment(time).format('HH:mm');
    }

    public handleReject() {
        this.emitReject.emit({
            userId: this.userId,
            tableId: this.tableId
        });
    }

    private passedSeconds(start, end) {
        return moment.duration(start.diff(end)).asSeconds();
    }
}
