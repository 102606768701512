import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-m-card-table-capacity',
    templateUrl: './m-card-table-capacity.component.html',
    styleUrls: ['./m-card-table-capacity.component.scss'],
})

export class MCardTableCapacityComponent {
    @Input() public tableCapacity = 1;
    @Input() public count: number;
    @Output() public emitChange = new EventEmitter<any>();

    public handleMin() {
        if (this.count === 0) {return;}
        this.emitChange.emit({
            tableCapacity: this.tableCapacity,
            count: this.count,
            calculation: 'down'
        });
        this.count--;
    }

    public handlePlus() {
        this.emitChange.emit({
            tableCapacity: this.tableCapacity,
            count: this.count,
            calculation: 'plus'
        });
        this.count++;
    }
}
