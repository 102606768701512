import {HttpClientModule, HttpClient} from '@angular/common/http';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { RouterModule } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared/shared.module';
import { GraphQLModule } from './graphql.module';
import { StorageService } from './services/ionic-storage.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MessageService } from 'primeng/api';

import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { ToastModule } from 'primeng/toast';
import { RegistrationService } from './services/registration.service';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

IonicModule.forRoot({animated: false});

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        SharedModule,
        GraphQLModule,
        RouterModule,
        ToastModule,
        TranslateModule.forRoot({
            defaultLanguage: 'nl',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        InAppBrowser,
        FirebaseX,
        {provide: RegistrationService},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: StorageService},
        {provide: MessageService}
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
