import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { Table } from '../../../generated/graphql';
import { RegistrationService } from '../../../services/registration.service';
import { GuestsService } from '../../../services/guests.service';
import * as moment from 'moment';

@Component({
    selector: 'app-c-guests-today',
    templateUrl: './c-guests-today.component.html',
    styleUrls: ['./c-guests-today.component.scss'],
})
export class CGuestsTodayComponent implements OnInit, OnDestroy {

    public todayTables$: BehaviorSubject<Table[]> = new BehaviorSubject([]);
    public subscriptions$ = new Subscription();

    constructor(
        private registrationService: RegistrationService,
        private guestsService: GuestsService
    ) {
    }

    ngOnInit() {
        this.getTodayClaims();
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    private getTodayClaims() {

        this.subscriptions$.add(combineLatest([
            this.registrationService.state.token,
            this.registrationService.state.restaurantId,
        ]).subscribe((combined) => {
            if (combined[0] !== '' && combined[1] !== '') {
                const today = moment().format('yyyy-MM-DD');
                // @ts-ignore
                this.guestsService.getHistoryRestaurantClaims(today).then((response) => {
                    this.todayTables$.next(response.data.restaurantClaims.data as Table[]);
                });
            }
        }));
    }
}
