import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { RegistrationService } from './services/registration.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { MessageService } from 'primeng/api';
import { GuestsService } from './services/guests.service';
import { TablesService } from './services/tables.service';
import { StorageService } from './services/ionic-storage.service';
import { LanguageService } from './services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiVersionService } from './services/apiVersion.service';
import { Globals } from './config/globals';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

    public subscriptions$ = new Subscription();
    public currentRestaurantId$ = this.registrationService.state.restaurantId;
    public showUpdateModal = false;
    public linkAndroid: string;
    public linkIos: string;
    public updateHeading: string;
    public updateText: string;

    constructor(
        private firebase: FirebaseX,
        private registrationService: RegistrationService,
        private guestsService: GuestsService,
        private tablesService: TablesService,
        private router: Router,
        private platform: Platform,
        private messageService: MessageService,
        private ngZone: NgZone,
        private storageService: StorageService,
        private translate: TranslateService,
        private languageService: LanguageService,
        public apiVersionService: ApiVersionService,
    ) {}

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    ngOnInit() {
        // check api version if we need to update
        this.messageService.clear();
        this.linkAndroid = Globals.config.APP_LINKS.ANDROID;
        this.linkIos = Globals.config.APP_LINKS.IOS;
        this.apiVersionService.getApiVersion().then(data => {
            const serverVersionHigher = this.apiVersionService.checkApiVersion(data.data.apiVersion);
            this.showUpdateModal = this.platform.is('cordova') && serverVersionHigher;
            console.log('showUpdateModal', this.showUpdateModal);
            this.updateHeading = this.translate.instant('general.update-heading');
            this.updateText = this.translate.instant('general.update-text');
            this.init();
        });
        this.registrationService.getCountries().then(resp => {
            localStorage.setItem('countries', JSON.stringify(resp.data.countries));
        });
        // this.showUpdateModal = this.platform.is('cordova') && this.apiVersionService.getApiVersion();
    }

    init() {
        // setup push
        if (this.platform.is('cordova')) {
            this.platform.ready().then(() => {
                this.firebase.grantPermission().then();
                this.firebase.getToken().then(token => {
                    this.registrationService.saveFcmTokenToStorage(token);
                    console.log(`The token:`, token);
                });
                this.firebase.onMessageReceived().subscribe(async (data) => {
                    const userId = await this.storageService.get('userId');
                    console.log(`FCM message received, userId`, data, userId);
                    this.messageService.clear();
                    if (data.notification_type) {
                        console.log(`data.notification_type`, data.notification_type);
                        if (data.notification_type === 'claim') {
                            const message = userId ? data?.notification_message : this.translate.instant('push.push-login-first');
                            console.log('message', message);
                            if(!userId) {
                                this.messageService.add({
                                    severity: 'info',
                                    summary: this.translate.instant('push.new-booking'),
                                    detail: message ?? ''
                                });
                            } else {
                                this.messageService.add({
                                    severity: 'info',
                                    summary: data?.notification_title ?? this.translate.instant('push.new-booking'),
                                    detail: message ?? ''
                                });
                                this.gotoMyGuests();
                            }
                        } else {
                            this.messageService.add({
                                severity: 'info',
                                summary: data?.notification_title ?? this.translate.instant('push.new-message'),
                                detail: data?.notification_message ?? ''
                            });
                        }
                    }
                });
            });
        }
    }

    private gotoMyGuests() {
        console.log('gotoMyGuests');
        this.ngZone.run(() => {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/my-guests']);
        });
    }
}
