import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/ionic-storage.service';
import { RegistrationService } from '../services/registration.service';

@Injectable({
    providedIn: 'root'
})
export class MustBeLoggedInGuard implements CanActivate {

    constructor(
        private registrationService: RegistrationService,
        private storageService: StorageService,
        private router: Router,
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return Promise.all([
            this.storageService.get('restaurantId'),
            this.storageService.get('token'),
            this.storageService.get('userId')
        ]).then((result) => {
            if (result.includes(null)) {
                this.clearStorage().then(() => {
                    this.handleRedirect();
                });
            } else {
                return this.registrationService.getMe().then((res) => {
                    if (res.data.me !== null) {
                        return true;
                    } else {
                        this.clearStorage().then(() => {
                            this.handleRedirect();
                        });
                    }
                });
            }
        });
    }

    private handleRedirect() {
        this.router.navigate(['/home']);
    }

    private clearStorage() {
        return Promise.all([
            this.storageService.remove('restaurantId'),
            this.storageService.remove('token'),
            this.storageService.remove('userId')
        ]);
    }

}
