import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface establishment {
  id: string;
  image: string;
  title: string;
  location: string;
  distance: string;
  rating: string;
  telephoneNumber: string;
  status: string;
  amountOfPersons: string;
  time: string;
}

@Component({
    selector: 'app-o-card-establishment-items',
    templateUrl: './o-card-establishment-items.component.html',
    styleUrls: ['./o-card-establishment-items.component.scss'],
})
export class OCardEstablishmentItemsComponent implements OnInit {

  @Output() emitChange = new EventEmitter();

  @Input() public establishments: establishment[] = [];

  constructor() { }

  ngOnInit() {}

  public emitOnChange(childData) {
      this.emitChange.emit(childData);
  }
}
