import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Component({
    selector: 'app-m-image-order-item',
    templateUrl: './m-image-order-item.component.html',
    styleUrls: ['./m-image-order-item.component.scss'],
})
export class MImageOrderItemComponent implements OnInit {
    @Input() image: string;
    @Input() file: File;
    @Output() changeOfOrder: EventEmitter<{ direction: 'up' | 'down'; file: File }> = new EventEmitter();
    @Output() remove: EventEmitter<{id: string; file: File}> = new EventEmitter();

    public handledImage = new BehaviorSubject('');

    constructor() {
    }

    ngOnInit() {
        this.convertFile(this.file).subscribe((base64) => {
            this.handledImage.next(`data:${this.file.type};base64,${base64}`);
        });
    }

    public handleChangeOfOrder(direction: 'up' | 'down') {
        this.changeOfOrder.emit({
            direction,
            file: this.file
        });
    }

    public removeFromList() {
        this.remove.emit({id: this.image, file: this.file});
    }

    private convertFile(file: File): Observable<string> {
        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => result.next(btoa(event.target.result.toString()));
        return result;
    }
}

