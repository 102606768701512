import { Component, OnInit } from '@angular/core';
import { openingTimeEntry, RegistrationService } from '../../../services/registration.service';
import { StorageService } from '../../../services/ionic-storage.service';
import { RestaurantsService } from '../../../services/restaurants.service';
import { BusinessHour } from '../../../generated/graphql';


@Component({
    selector: 'app-c-card-opening-times',
    templateUrl: './c-card-opening-times.component.html',
    styleUrls: ['./c-card-opening-times.component.scss'],
})
export class CCardOpeningTimesComponent implements OnInit {

    public serviceOpeningTimes = this.registrationService.state.openingTimes;
    public openingTimesArray: BusinessHour[] = [];
    public showOpeningTimes = false;
    public allOpeningTimes: { dayNumber: number; day: string; hours: any}[] = [];
    public days: { num: number; day: string}[] = [];

    constructor(
        private registrationService: RegistrationService,
        private storageService: StorageService,
        private restaurantsService: RestaurantsService,
    ) {
    }

    public ngOnInit() {
        this.storageService.get('restaurantId').then((id) => {
            if (id) {
                this.restaurantsService.getRestaurantById(id).then((res) => {
                    const restaurant = res.data.restaurantById;
                    this.openingTimesArray = restaurant.businessHours as BusinessHour[];
                    this.registrationService.state.openingTimes.next(this.openingTimesArray);
                    console.log('openingTimesArray after getting restaurantById', this.openingTimesArray);
                    this.setOpeningDays();
                });
            } else {
                this.setOpeningDays();
            }
            this.showOpeningTimes = true;
        });
    }

    public setOpeningDays()  {
        this.days = this.restaurantsService.getWeekDays();
        this.days.forEach( day => {
            const openingTime = {
                day: day.day,
                dayNumber: day.num,
                hours: this.getPrefilled(day.num),
            };
            this.allOpeningTimes.push(openingTime);
        });
        console.log('days', this.days);
    }

    public getPrefilled(dayOfWeek: number) {
        const found = this.openingTimesArray.filter((hour) => Number(hour.dayOfWeek) === dayOfWeek);
        if (found[0]) {
            return found[0];
        } else {
            return null;
        }
    }

    public handleOpeningTime(event: openingTimeEntry) {
        if (event.isEnabled) {
            this.addOrUpdateEntry(event);
        } else {
            this.removeEntry(event);
        }
        this.registrationService.state.openingTimes.next(this.openingTimesArray);
    }

    private addOrUpdateEntry(event) {
        const foundEntry = this.openingTimesArray.findIndex((listItem) => Number(listItem.dayOfWeek) === event.dayOfWeek);
        if (foundEntry !== -1) {
            this.openingTimesArray[foundEntry] = event;
        } else {
            this.openingTimesArray.push(event);
        }
    }

    private removeEntry(event) {
        console.log(this.openingTimesArray);
        this.openingTimesArray = this.openingTimesArray.filter((listItem) => {
            console.log(listItem.dayOfWeek, event.dayOfWeek);
            return Number(listItem.dayOfWeek) !== event.dayOfWeek;
        });
        console.log(this.openingTimesArray);
    }

}
