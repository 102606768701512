import { Directive, Renderer2, HostBinding, Input, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[appAInput]'
})
export class AInputDirective implements AfterViewInit {

  @Input() isSecondary = false;

  constructor(private renderer: Renderer2, private el: ElementRef) {
  }

  @HostBinding('class') get classes(): string {
      const classes: Array<string> = [];

      classes.push('a-input');

      if (this.isSecondary) {classes.push('a-input--secondary');}

      return classes.join(' ');
  }

  ngAfterViewInit() {
      if (this.el.nativeElement.nodeName === 'SELECT') {
          const parent = this.el.nativeElement.parentNode;
          const divElement = this.renderer.createElement('div');
          this.renderer.addClass(divElement, 'a-input__select-wrapper');
          if (this.isSecondary) {this.renderer.addClass(divElement, 'a-input__select-wrapper--secondary');}
          this.renderer.insertBefore(parent, divElement, this.el.nativeElement);
          this.renderer.removeChild(parent, this.el.nativeElement);
          this.renderer.removeAttribute(this.el.nativeElement, 'inputWrapper');
          this.renderer.appendChild(divElement, this.el.nativeElement);
      }
  }
}
