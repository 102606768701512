import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-c-opening-times',
    templateUrl: './c-opening-times.component.html',
    styleUrls: ['./c-opening-times.component.scss'],
})
export class COpeningTimesComponent implements OnInit {

    constructor() { }

    ngOnInit() {}

}
