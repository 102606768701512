import { Component, EventEmitter, Input, Output } from '@angular/core';

interface image {
    restaurantImage: string;
}

@Component({
    selector: 'app-o-image-order-items',
    templateUrl: './o-image-order-items.component.html',
    styleUrls: ['./o-image-order-items.component.scss'],
})
export class OImageOrderItemsComponent {
    @Output() emitChange = new EventEmitter();
    @Input() public images: image[];

    public emitOnChange(childData) {
        console.log(childData);
        this.emitChange.emit(childData);
    }

    public removeFromList(addedImage) {
        const index = this.images.indexOf(addedImage);

        this.images.splice(index, 1);
    }
}
