import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[appAParagraph]',
})

export class AParagraphDirective {

  @Input() color: 'dark' |'grey'| 'error' | '' = '';
  @Input() isSmall = false;


  @HostBinding('class') get classes(): string {
      const classes: Array<string> = [];

      classes.push('a-paragraph');
      if(this.color) {classes.push(`a-paragraph--${this.color}`);}
      if(this.isSmall) {classes.push(`a-paragraph--small`);}

      return classes.join(' ');
  }

}
