import { Injectable } from '@angular/core';
import {
    ForgotPasswordInput,
    ForgotPasswordMutationService,
    LoginInput,
    LoginMutationService,
    ResetPasswordInput,
    ResetPasswordMutationService
} from '../generated/graphql';
import { RegistrationService } from './registration.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AccountActionsService {

    constructor(
        private translate: TranslateService,
        private loginMutationService: LoginMutationService,
        private registrationService: RegistrationService,
        private resetPasswordMutationService: ResetPasswordMutationService,
        private forgotPasswordMutationService: ForgotPasswordMutationService
    ) {
    }

    public login(loginInput: LoginInput) {
        return this.loginMutationService.mutate({input: loginInput}).toPromise().then((res) => {
            try{
                if(
                    res.data.login.user
                    && res.data.login.user.id
                    && res.data.login.user.restaurant
                    && res.data.login.user.restaurant.id
                ) {
                    this.registrationService.saveUserIdToStorage(res.data.login.user.id);
                    this.registrationService.saveAuthTokenToStorage(res.data.login.token);
                    this.registrationService.saveRestaurantIdToStorage(res.data.login.user.restaurant.id);
                    return res;
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw(this.translate.instant('login.error'));
                }
            }
            catch(e) {
                // eslint-disable-next-line no-throw-literal
                throw(this.translate.instant('login.error'));
            }
        });
    }

    public logout() {
        return Promise.all([
            this.registrationService.saveAuthTokenToStorage(''),
            this.registrationService.saveUserIdToStorage(''),
            this.registrationService.saveRestaurantIdToStorage(''),
        ]
        );
    }

    public forgotPassword(forgotPasswordInput: ForgotPasswordInput) {
        return this.forgotPasswordMutationService.mutate({input: forgotPasswordInput}).toPromise();
    }

    public resetPassword(resetPasswordInput: ResetPasswordInput) {
        return this.resetPasswordMutationService.mutate({input: resetPasswordInput}).toPromise();
    }
}
