import { Component, Input, OnInit } from '@angular/core';
import { RestaurantsService } from '../../../../services/restaurants.service';
import { ExtraInfoTranslations } from '../../../../generated/graphql';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-m-restaurant-info',
    templateUrl: './m-restaurant-info.component.html',
    styleUrls: ['./m-restaurant-info.component.scss'],
})
export class MRestaurantInfoComponent implements OnInit {

    @Input() title = 'Restaurant Morris';
    @Input() location = 'Spaarne 36, Haarlem';
    @Input() time = '';
    @Input() type = 'Bourgondisch shared dining';
    @Input() rating = 1;
    @Input() price = 0;
    @Input() extraInfo: ExtraInfoTranslations;

    public priceIndication = '';

    constructor(
        private restaurantsService: RestaurantsService,
        public translateService: TranslateService,
    ) {
    }

    ngOnInit(): void {
        if (this.price) {
            this.priceIndication = this.restaurantsService.priceIndication(this.price);
        }
    }
}
