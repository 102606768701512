import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
    selector: 'app-c-form-error',
    templateUrl: './c-form-error.component.html',
    styleUrls: ['./c-form-error.component.scss'],
})
export class CFormErrorComponent implements OnInit {

    @Input() control: AbstractControl = new FormControl();
    @Input() message = 'No Validation set';

    constructor() {
    }

    ngOnInit() {
    }

}
