import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { ATextHeadingDirective } from '../../components/ftable/atoms/a-text-heading.directive';
import { AButtonDirective } from '../../components/ftable/atoms/a-button.directive';
import { ALabelDirective } from '../../components/ftable/atoms/a-label.directive';
import { AInputDirective } from '../../components/ftable/atoms/a-input.directive';
import { AParagraphDirective } from '../../components/ftable/atoms/a-paragraph.directive';
import { MCardEstablishmentItemComponent }
    from '../../components/ftable/molecules/m-card-establishment-item/m-card-establishment-item.component';
import { CPopupComponent } from '../../components/ftable/containers/c-popup/c-popup.component';
import { MImageOrderItemComponent }
    from '../../components/ftable/molecules/m-image-order-item/m-image-order-item.component';
import { MCardGuestItemComponent }
    from '../../components/ftable/molecules/m-card-guest-item/m-card-guest-item.component';
import { MRestaurantInfoComponent }
    from '../../components/ftable/molecules/m-restaurant-info/m-restaurant-info.component';
import { OCardEstablishmentItemsComponent }
    from '../../components/ftable/organisms/o-card-establishment-items/o-card-establishment-items.component';
import { CEstablishmentItemsComponent }
    from '../../components/containers/c-establishment-items/c-establishment-items.component';
import { MMenuBarComponent } from '../../components/ftable/molecules/m-menu-bar/m-menu-bar.component';
import { OImageOrderItemsComponent }
    from '../../components/ftable/organisms/o-image-order-items/o-image-order-items.component';
import { CRestaurantDetailsComponent } from '../../components/containers/c-restaurant-details/c-restaurant-details.component';
import { CSettingsFormComponent } from '../../components/containers/c-settings-form/c-settings-form.component';
import { CMyAccountComponent } from '../../components/containers/c-my-account/c-my-account.component';
import { CTableAvailabilityComponent } from '../../components/containers/c-table-availability/c-table-availability.component';
import { OCardTablesCapacityComponent }
    from '../../components/ftable/organisms/o-card-tables-capacity/o-card-tables-capacity.component';
import { MCardTableCapacityComponent }
    from '../../components/ftable/molecules/m-card-table-capacity/m-card-table-capacity.component';
import { OMenuLightboxComponent }
    from '../../components/ftable/organisms/o-menu-lightbox/o-menu-lightbox.component';
import { MCardQuestionPhoneComponent }
    from '../../components/ftable/molecules/m-card-question-phone/m-card-question-phone.component';
import { MBlockLightboxComponent }
    from '../../components/ftable/molecules/m-block-lightbox/m-block-lightbox.component';
// eslint-disable-next-line max-len
import { CCardOpeningTimesSpecialComponent } from '../../components/containers/c-card-opening-times-special/c-card-opening-times-special.component';
import { CAppDownloadsComponent } from '../../components/containers/c-app-downloads/c-app-downloads.component';

/** Ng Prime Modules **/
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { SliderModule } from 'primeng/slider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { TabMenuModule } from 'primeng/tabmenu';
import { SkeletonModule } from 'primeng/skeleton';
import { TabViewModule } from 'primeng/tabview';
import { IonicModule } from '@ionic/angular';
import { GalleriaModule } from 'primeng/galleria';

import { MyAccountPopupComponent } from '../../components/modals/my-account-popup/my-account-popup.component';
import { SaveChangesPopupComponent } from '../../components/modals/save-changes-popup/save-changes-popup.component';
import { CNewPasswordFormComponent } from '../../components/containers/c-new-password-form/c-new-password-form.component';
import { CCardOpeningTimesComponent } from '../../components/containers/c-card-opening-times/c-card-opening-times.component';
import { MOpeningTimesComponent } from '../../components/molecules/m-opening-times/m-opening-times.component';
import { FileUploadModule } from 'primeng/fileupload';
import {
    CRestaurantImagesComponent
} from '../../components/containers/c-restaurant-images/c-restaurant-images.component';
import { CMenuFooterComponent } from '../../components/containers/c-menu-footer/c-menu-footer.component';
import { SpinnerModule } from 'primeng/spinner';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {
    MLoaderFullscreenComponent
} from '../../components/molecules/m-loader-fullscreen/m-loader-fullscreen.component';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { CGuestsCurrentComponent } from '../../components/containers/c-guests-current/c-guests-current.component';
import { CGuestsTodayComponent } from '../../components/containers/c-guests-today/c-guests-today.component';
import { CGuestsHistoryComponent } from '../../components/containers/c-guests-history/c-guests-history.component';
import { COpeningTimesComponent } from '../../components/containers/c-opening-times/c-opening-times.component';
import { CFormErrorComponent } from '../../components/containers/c-form-error/c-form-error.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
    declarations: [
        ATextHeadingDirective,
        AButtonDirective,
        ALabelDirective,
        AInputDirective,
        AParagraphDirective,
        CPopupComponent,
        CEstablishmentItemsComponent,
        CSettingsFormComponent,
        CMyAccountComponent,
        CNewPasswordFormComponent,
        CTableAvailabilityComponent,
        MCardEstablishmentItemComponent,
        MImageOrderItemComponent,
        MCardGuestItemComponent,
        MRestaurantInfoComponent,
        MMenuBarComponent,
        MCardTableCapacityComponent,
        MCardQuestionPhoneComponent,
        MBlockLightboxComponent,
        OCardEstablishmentItemsComponent,
        OImageOrderItemsComponent,
        OCardTablesCapacityComponent,
        OMenuLightboxComponent,
        MyAccountPopupComponent,
        SaveChangesPopupComponent,
        CRestaurantDetailsComponent,
        CCardOpeningTimesComponent,
        CCardOpeningTimesSpecialComponent,
        MOpeningTimesComponent,
        CRestaurantImagesComponent,
        CMenuFooterComponent,
        MLoaderFullscreenComponent,
        CGuestsCurrentComponent,
        CGuestsTodayComponent,
        CGuestsHistoryComponent,
        COpeningTimesComponent,
        CFormErrorComponent,
        CAppDownloadsComponent,
    ],
    imports: [
        CommonModule,
        DropdownModule,
        CheckboxModule,
        SliderModule,
        RadioButtonModule,
        InputSwitchModule,
        InputTextModule,
        InputNumberModule,
        PaginatorModule,
        ReactiveFormsModule,
        TabMenuModule,
        FormsModule,
        SkeletonModule,
        TabViewModule,
        IonicModule,
        GalleriaModule,
        FileUploadModule,
        SpinnerModule,
        ProgressSpinnerModule,
        MessageModule,
        ToastModule,
        TranslateModule,
    ],
    exports: [
        ATextHeadingDirective,
        AButtonDirective,
        ALabelDirective,
        AInputDirective,
        AParagraphDirective,
        CPopupComponent,
        CEstablishmentItemsComponent,
        CSettingsFormComponent,
        CMyAccountComponent,
        CTableAvailabilityComponent,
        CNewPasswordFormComponent,
        MCardEstablishmentItemComponent,
        MImageOrderItemComponent,
        MCardGuestItemComponent,
        MRestaurantInfoComponent,
        MMenuBarComponent,
        MCardTableCapacityComponent,
        MCardQuestionPhoneComponent,
        MBlockLightboxComponent,
        OImageOrderItemsComponent,
        OCardTablesCapacityComponent,
        OMenuLightboxComponent,
        DropdownModule,
        CheckboxModule,
        SliderModule,
        RadioButtonModule,
        InputSwitchModule,
        InputTextModule,
        InputNumberModule,
        PaginatorModule,
        TabMenuModule,
        ReactiveFormsModule,
        FormsModule,
        ReactiveFormsModule,
        TabViewModule,
        MyAccountPopupComponent,
        SaveChangesPopupComponent,
        CNewPasswordFormComponent,
        SaveChangesPopupComponent,
        CRestaurantDetailsComponent,
        CCardOpeningTimesComponent,
        CCardOpeningTimesSpecialComponent,
        CRestaurantImagesComponent,
        CMenuFooterComponent,
        CGuestsCurrentComponent,
        CGuestsTodayComponent,
        CGuestsHistoryComponent,
        MLoaderFullscreenComponent,
        COpeningTimesComponent,
        CFormErrorComponent,
        CAppDownloadsComponent,
    ]
})
export class SharedModule {
}
